<template>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">

                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Customer Add
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList">
                            Back  Customer List
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header  card-header-bg flex-wrap gap-2"></div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm" style="width: 100%;">

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Customer Name
                                        </label>
                                        <input required v-model="form.customer_name"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Address 1
                                        </label>
                                        <input  v-model="form.address_1"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Address 2
                                        </label>
                                        <input  v-model="form.address_2"
                                            class="shadow appearance-none border  border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-4 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Country
                                        </label>
                                        <select @change="handleCountryChange" id="country_id" ref="country_id" 
                                            v-model="form.country_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>-Select-</option>
                                            <option v-for="country in countty_list" :key="country.country_id"
                                                :value="country.country_id">
                                                {{ country.country_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Province
                                        </label>
                                        <select @change="handleProvinceChange"  v-model="form.province_id"
                                            ref="province_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <!-- Placeholder option -->
                                            <option v-for="province in province_list" :key="province.province_id"
                                                :value="province.province_id">
                                                {{ province.province_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            City
                                        </label>
                                        <select ref="city_id"  v-model="form.city_id" 
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="city in city_list" :key="city.city_id" :value="city.city_id">
                                                {{ city.city_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Zip Code
                                        </label>
                                        <input v-model="form.zip_code"
                                            class="shadow appearance-none border  border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Email Address
                                        </label>
                                        <input v-model="form.email"
                                            class="shadow appearance-none border  border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="email" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Phone Number
                                        </label>
                                        <input v-model="form.phone_number"
                                            class="shadow appearance-none border  border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Mobile Number
                                        </label>
                                        <input v-model="form.mobile_number"
                                            class="shadow appearance-none border  border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Contact Person
                                        </label>
                                        <input v-model="form.contact_person"
                                            class="shadow appearance-none border  border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Contact Email Address
                                        </label>
                                        <input v-model="form.contact_email_address"
                                            class="shadow appearance-none border  border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Contact Mobile Number
                                        </label>
                                        <input v-model="form.contact_mobile_number"
                                            class="shadow appearance-none border  border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>


                            <div class="flex justify-end mt-4">
                                
                                <button type="submit"
                                    class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Save</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal2.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';

export default {
    name: 'CustomerListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification
    },
    mounted() {
        this.CountryList(); // Call the function here
        this.form.country_id = 0;
    },
 
    methods: {
        
        backToList(){
            window.location=`/customer/list`;
        },
        async handleCountryChange() {
            //this.currentItem.province_id = ""; // Clear selected province
            // this.currentItem.city_id = "";

          
            this.form.province_id = 0;
            this.form.city_id = 0;
            await this.fetchProvinces(); // Fetch new provinces based on the selected country
        },

        async handleProvinceChange() {
            // this.currentItem.city_id = ""; // Clear selected province
            this.city_list = []; // Clear province list
            await this.fetchCity(); // Fetch new provinces based on the selected country
        },

        async fetchCity() {
            //if (!this.currentItem.province_id) return; // Skip if no country is selected
           

            let province_id = "";
            if (this.showModal2 == true) {
                province_id = this.$refs.province_id2.value;
                if (province_id == "") {
                    province_id = this.currentItem.province_id;
                }

            } else {
                province_id = this.$refs.province_id.value;
            }

            if (province_id == "") {
               
                return false;
            }

            try {
                const response = await apiClient.get(`/city/all/${province_id}`);
                if (response.data.status === true) { // or check for other success condition
                    this.city_list = response.data.data;
                    this.$refs.city_id.value=0;
                }
            } catch (error) {
                console.error('Error fetching provinces:', error.response);
            } finally {
                
            }
        },

        async fetchProvinces() {
            let countryId = "";
            if (this.showModal2 == true) {
                countryId = this.$refs.country_id2.value;
                if (countryId == "") {
                    countryId = this.currentItem.country_id;
                }
            } else {
                countryId = this.$refs.country_id.value;
            }

            if (countryId == "") {
              
                return false;
            }

            try {
                const response = await apiClient.get(`/province/all/${countryId}`);
                if (response.data.status === true) { // or check for other success condition
                    this.province_list = response.data.data;
                    this.handleProvinceChange();
                }
            } catch (error) {
                console.error('Error fetching provinces:', error.response);
            } finally {
               
            }
        },
        async CountryList() {        
            try {
                const response = await apiClient.get('/country/list/all');
                if (response.data.status == true) { //                 
                    this.countty_list = response.data.data;
                    this.handleCountryChange();
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
            
            }
        },       
       
        async submitForm() {
          
            try {
                const response = await apiClient.post('/customer/save', this.form);
                if (response.data.status == true) { 
                    this.showModal = false;
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '/customer/list');
                } else {                  
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                
            }
        }

    },
    data() {
        return {           
            form: {},
           
            currentItem: [

            ],
            countty_list: [],
            province_list: [],
            city_list: [],
            edit_first: 0
        };
    },


}
</script>


<style></style>