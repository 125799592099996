<template>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Order Edit
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList"> Back to Order list</a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2"></div>
                    <div class="card-body">

                        <form @submit.prevent="submitForm" style="width: 100%" ref="form">
                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Customer
                                        </label>
                                        <input required ref="customer_name" @click="showModalCustomer" @keydown.prevent
                                            style="cursor: pointer;"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="Select Customer Name" />
                                    </div>
                                </div>

                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Order Number
                                        </label>
                                        <input required v-model="currentItem.order_number"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="" />
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Order Date
                                        </label>
                                        <input required v-model="currentItem.order_date"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="date" placeholder="" />
                                    </div>
                                </div>
                            </div>


                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Grand Total
                                        </label>
                                        <input v-model="currentItem.grand_total" ref="grandtotal"
                                            class="shadow  bg-gray-400 appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" disabled="" value="0.00" />
                                    </div>
                                </div>

                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Payment
                                        </label>
                                        <select required v-model="currentItem.payment_id" ref="payment_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                            <!-- Placeholder option -->
                                            <option v-for="payment in payment_list" :key="payment.payment_id"
                                                :value="payment.payment_id">
                                                {{ payment.payment_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Reference
                                        </label>
                                        <input v-model="currentItem.payment_reference"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="" />
                                    </div>
                                </div>
                            </div>


                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Amount Paid
                                        </label>
                                        <input required v-model="currentItem.amount_paid" ref="amountpaid"  @keyup="addAmountPaid"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="" />
                                    </div>
                                </div>

                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Balance
                                        </label>

                                        <input required v-model="currentItem.balance"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="input" disabled placeholder="" />
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-4">
                                    <div class="card">
                                        <div class="card-header bg-black flex-wrap gap-2">
                                            <div class="flex flex-wrap gap-2 lg:gap-5">
                                                <div class="flex">
                                                    <label class="text-white"> Product Item(s) </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body">
                                            <div class="grid grid-cols-4 gap-4">
                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Product Code / Name
                                                    </label>

                                                    <SearchableSelect v-model="selectedOption" :options="options"
                                                        :labelKey="'product_info'" placeholder="Search and select"
                                                        @option-selected="handleOption" />
                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Cost Price
                                                    </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input ref="cost_price"
                                                            class="shadow appearance-none border bg-gray-400 border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="number" disabled placeholder="" min="0" value="0" />
                                                    </div>
                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Retailing Price
                                                    </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input ref="retail_price"
                                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="number" placeholder="" min="0" value="0" />
                                                    </div>
                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        &nbsp; </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <button type="button" @click="addRow"
                                                            class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                                            Add Product Item
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="grid grid-cols-4 gap-4">

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Discount Price
                                                    </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input ref="discount_price"
                                                            class="shadow appearance-none bg-gray-400 border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="number" placeholder="" disabled min="0" value="0" />
                                                    </div>
                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Quantity
                                                    </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input ref="pquantity"
                                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="number" placeholder="" min="1" value="1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="grid grid-cols-5 gap-4">
                                                <div class="col-span-5">
                                                    <table style="font-size:13px"
                                                        class="min-w-full border-collapse border border-gray-300">
                                                        <thead>
                                                            <tr class="bg-gray-100">
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Code
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Name
                                                                </th>


                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Retail
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Quantity
                                                                </th>

                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Total Price
                                                                </th>

                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in items" :key="index"
                                                                class="odd:bg-white even:bg-gray-50">
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.code }}
                                                                </td>
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.name }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.retail }}
                                                                </td>


                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.quantity }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.total }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    <i @click="removeRow(index)"
                                                                        class="text-red-500 px-2 py-1 rounded hover:bg-black-600 ki-filled ki-trash fs-2tx">
                                                                    </i>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="flex justify-end mt-4">
                                <button type="submit"
                                    class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-1">
                                    Save
                                </button>
                                <button type="button" @click="handlePosted()"
                                    class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                                    Out From Warehouse
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Modal :isVisible="showModaCustomer" @update:isVisible="showModaCustomer = $event" @click.stop>
                <template v-slot:header>
                    <h2>Edit Category</h2>
                </template>
                <template v-slot:body>
                    <CustomerTable @row-clicked="handleRowClicked"></CustomerTable>
                </template>
            </Modal>


            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from "@/components/MainLayout.vue";
import apiClient from "@/services/apigen"; // Import your configured Axios instance
import { ref, watch, onMounted } from "vue";
import { VueGoodTable } from "vue-good-table-next";
import Modal from "@/components/utils/Modal3.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import Loader from "@/components/utils/loader.vue";
import Swal from "sweetalert2";
import Notification from "@/components/utils/Notification.vue";
import SearchableSelect from "@/components/utils/Search.vue";
import CustomerTable from "@/components/utils/Customer_Modal.vue";

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default {
    name: "supplierListPage",
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        SearchableSelect,
        CustomerTable
    },
    setup() {



    },
    mounted() {
        this.getpayment(); // Call the function here
        let order_id = this.$route.params.id; // Accessing route params via 'this'      
        this.showInfo(order_id);
        this.fetchSuggestions2();

    },
    created() {
        // Initialize the debounced function
        this.fetchSuggestionsDebounced = debounce(this.fetchSuggestions, 1000);
        this.fetchSuggestionsDebounced2 = debounce(this.fetchSuggestions2, 1000);
        this.fetchSuggestionsDebounced3 = debounce(this.fetchSuggestions3, 1000);
    },
    methods: {

        addAmountPaid() {

            let gtotal = this.currentItem.grand_total
            if (gtotal != 0) {

                let balance = 0;
                balance = parseFloat(this.currentItem.grand_total) - parseFloat(this.currentItem.amount_paid);
                if (balance < 0) {
                    balance = 0;
                }
                this.currentItem.balance = balance.toFixed(2);

            } else {
                //this.currentItem.amount_paid = "";
                //this.$refs.notificationRef.show("Please add Product First!", 'error', '0');
            }
        },
        compute_grandtotal() {
            let grand_total = 0;
            for (let i = 0; i < this.items.length; i++) {
                grand_total = parseFloat(grand_total) + (this.items[i].retail * this.items[i].quantity);
            }
            
            this.currentItem.grand_total = grand_total.toFixed(2);
            let balance = 0;
            if (this.currentItem.amount_paid== undefined || this.currentItem.amount_paid == "") {
                this.currentItem.balance = grand_total.toFixed(2);
              
            } else {

                balance = parseFloat(this.currentItem.grand_total) - parseFloat(this.currentItem.amount_paid);
                if (balance < 0) {
                    balance = 0;
                }
                this.currentItem.balance = balance.toFixed(2);
            }

        },
        showModalCustomer() {
            this.showModaCustomer = true;
        },
        handleRowClicked(rowData) {
            this.showModaCustomer = false;
            this.select_customer = rowData
            this.$refs.customer_name.value = rowData.customer_name;
        },

        handleOption(option) {
            console.log(option);
            this.selectedOption = option; // Update selected option from event*/
            this.$refs.cost_price.value = option.original_price;
            this.$refs.retail_price.value = option.selling_price;
            this.$refs.discount_price.value = option.discount_price;

        },

        addRow() {

            if (Object.keys(this.selectedOption).length == 0) {
                this.$refs.notificationRef.show("Product Not found", 'error', '0');
                return false;
            }
            if (parseInt(Object.keys(this.items).length) != 0) {
                const exists = this.items.some(item => item.product_id === this.selectedOption.product_id);
                if (exists == true) {
                    this.$refs.notificationRef.show("Product already added to your list", 'error', '0');
                    return false;
                }
            }
            let code = this.selectedOption.product_code;
            let name = this.selectedOption.product_name;
            let quantity = this.$refs.pquantity.value;

            let product_id = this.selectedOption.product_id;
            let retail_price = this.$refs.retail_price.value;
            let total = (parseFloat(quantity * retail_price)).toFixed(2);;

           this.items.push({
                product_id: product_id, code: code, name: name,
                quantity: quantity,
                total: total, retail: retail_price
            });

            this.compute_grandtotal();

        },
        removeRow(index) {
            // Remove the row at the specified index
            this.items.splice(index, 1);
            this.compute_grandtotal();
        },

        handleKeyup2() {
            this.fetchSuggestionsDebounced2(); // Call the debounced function
        },
        handleKeyup() {
            this.fetchSuggestionsDebounced(); // Call the debounced function
        },

        handleKeyup3() {
            this.fetchSuggestionsDebounced3(); // Call the debounced function
        },

        clear_products_bottom() {
            this.productSearch2 = {};
            this.searchQuery2 = "";
            this.items = [];

        },
        async showInfo(purchase_order_id) {

            let id = purchase_order_id;
            const response = await apiClient.get(`/order/${id}`);
            this.currentItem = response.data.data; // Store the fetched data
            this.$refs.customer_name.value = this.currentItem.customer_name;
            this.select_customer.customer_id = response.data.data.customer_id;


            const response2 = await apiClient.get(`/order_product/${id}`);
            //this.items.push({ supplier_product_id: supplier_product_id, code: code, name: name, cost: cost, quantity: quantity, total: total });
            this.items = response2.data.data; // Store the fetched data

        },


        async fetchSuggestions3() {
            if (this.searchQuery3.length > 2) {
                this.indication_purchase = "(Searching...)";
                try {
                    const response = await apiClient.post("/purchase_order_product/get/list", {
                        data: { search: this.searchQuery3 },
                    });

                    if (response.data.status == true) {
                        this.suggestions3 = response.data.data;
                        if (response.data.data.length == 0) {
                            this.indication_purchase = "(Purchase Order not Found...)";
                            this.form.supplier_id = 0;
                        } else {
                            this.indication_purchase = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters
                this.clear_products_bottom();

            }
        },

        handlePosted() {

            const form = this.$refs.form;
            if (form.checkValidity()) {

                let data = {};
                data.items = this.items;

                // Check if items array is empty
                /*if (data.items.length == 0) {
                    // Trigger the notification if no items are selected
                    this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                    return false;  // Prevent submission
                }*/

                // Show SweetAlert confirmation dialog
                Swal.fire({
                    title: 'Are you sure do you want to out it from warehouse?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!',
                    cancelButtonText: 'No, cancel!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Proceed with form submission after confirmation
                        this.currentItem.out_from_warehouse = 1;
                        this.submitForm();  // Call your form submission logic here
                    } else {
                        // If the user cancels, stop everything
                        return false;
                    }
                });

            } else {
                // If the form is invalid, trigger the native validation popups
                form.reportValidity();
                return false;  // Prevent submission if form is invalid
            }

        },
        async fetchSuggestions() {
            if (this.searchQuery.length > 2) {
                this.indication_supplier = "(Searching...)";
                try {
                    const response = await apiClient.post("/suppplier/get/list", {
                        data: { search: this.searchQuery },
                    });

                    if (response.data.status == true) {
                        this.suggestions = response.data.data;
                        if (response.data.data.length == 0) {
                            this.indication_supplier = "(Supplier not Found...)";
                            this.form.supplier_id = 0;
                        } else {
                            this.indication_supplier = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters


            }
        },
        async fetchSuggestions2() {

            try {
                const response = await apiClient.post(`/product/name_code/keyword2`, {
                    data: { keyword: this.searchQuery2 },
                });

                if (response.data.status == true) {
                    // this.suggestions2 = response.data.data;
                    this.options = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
        },


        selectSuggestion(suggestion) {
            this.searchQuery = suggestion.supplier_name; // Populate the input with the selected suggestion
            this.productSearch = suggestion;
            this.form.supplier_id = suggestion.supplier_id;
            this.suggestions = []; // Clear suggestions list
        },

        selectSuggestion2(suggestion) {
            this.searchQuery2 = suggestion.product_name; // Populate the input with the selected suggestion
            this.productSearch2 = suggestion;
            //this.form.supplier_id = suggestion.supplier_id;
            this.suggestions2 = []; // Clear suggestions list
        },

        selectSuggestion3(suggestion) {
            this.searchQuery3 = suggestion.purchase_order_number; // Populate the input with the selected suggestion
            this.productSearch3 = suggestion;
            this.form.purchase_order_id = suggestion.purchase_order_id;
            this.suggestions3 = []; // Clear suggestions list
        },

        backToList() {
            window.location = `/customer/order_list`;
        },
        async getpayment() {
            try {
                const response = await apiClient.get("/payment/get/all");
                if (response.data.status == true) {
                    //
                    this.payment_list = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
        },
        async submitForm() {

            let data = {};
            data.items = this.items;
            this.currentItem = { ...this.currentItem, ...data };
            this.currentItem.customer_id = this.select_customer.customer_id;


            /*
            if (data.items.length == 0) {
                this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                return false;
            }*/

            try {
                const response = await apiClient.put(`/order/update`, this.currentItem);

                if (response.data.status == true) {
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '/customer/order_list');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        }

    },
    data() {
        return {
            form: {},
            currentItem: [],
            suggestions: [],
            searchQuery: "",
            productSearch: [],
            isLoading: false, // Loading state
            indication_supplier: "",
            payment_list: [],
            suggestions2: [],
            searchQuery2: "",
            indication_code: "",
            items: [],
            productSearch2: {},
            suggestions3: [],
            indication_purchase: "",
            searchQuery3: "",

            searchTerm: "",
            selectedOption: [], // Stores the selected option
            dropdownOpen: false, // Controls whether the dropdown is open
            showModaCustomer: false,
            select_customer: {},
            options: []


        };
    },
};
</script>

<style>
.search-select {
    position: relative;
}

.options-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.option-item {
    padding: 10px;
    cursor: pointer;
}

.option-item:hover {
    background-color: #f0f0f0;
}

.selected-option {
    margin-top: 10px;
}
</style>
