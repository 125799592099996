<template>
    <div style="background:#20B2FF"
        class="sidebar bg-gradient-to-r from-cyan-500 to-blue-500 border-r border-r-gray-200 dark:border-r-coal-100 fixed top-0 bottom-0 z-20 hidden lg:flex flex-col items-stretch shrink-0"
        data-drawer="true" data-drawer-class="drawer drawer-start top-0 bottom-0" data-drawer-enable="true|lg:false"
        id="sidebar">
        <div class="sidebar-header hidden lg:flex items-center relative justify-between px-3 lg:px-6 shrink-0"
            id="sidebar_header">
            <a class="dark:hidden" href="/dashboard">
                <img class="default-logo min-h-[33333333333px] max-w-none" src="@/assets/longlong.png"
                    style="width:195px" />
                <img class="small-logo min-h-[12222210px] max-w-none" src="@/assets/logoonly.png" style="width:30px">

            </a>
            <a class="hidden dark:block" href="/dashboard">
                <img class="default-logo min-h-[22px] max-w-none" src="@/assets/InventoLogo.png" />
                <img class="small-logo min-h-[22px] max-w-none" src="@/assets/InventoLogo.png" />
            </a>
            <button
                class="btn btn-icon btn-icon-md size-[30px] rounded-lg border border-gray-200 dark:border-gray-300 bg-light text-gray-500 hover:text-gray-700 toggle absolute left-full top-2/4 -translate-x-2/4 -translate-y-2/4"
                data-toggle="body" data-toggle-class="sidebar-collapse" id="sidebar_toggle">
                <i class="ki-filled ki-black-left-line toggle-active:rotate-180 transition-all duration-300"></i>
            </button>
        </div>
        <SidebarPage></SidebarPage>
    </div>
    <div class="wrapper flex grow flex-col">
        <headerPage></headerPage>
        <main class="grow content pt-5" id="content" role="content" style="min-height:800px;background:#bfdbfe">
            <slot></slot>
        </main>
        <FooterPage></FooterPage>   
       
        <!--end::Page layout-->
        <!--begin::Page scripts-->
    </div>
</template>

<script>
import Sidebar from './Sidebar.vue';

import HeaderPage from './Header.vue'
import SidebarPage from './Sidebar.vue'
import FooterPage from './Footer.vue'
export default {
    name: 'MainLayout',
    components: {
        HeaderPage,
        SidebarPage,
        FooterPage
        // 
    },
    mounted() {
        // Dynamically load the script when the component is mounted
        const script = document.createElement('script');
        script.src = `${process.env.BASE_URL}assets/js/core.bundle.js`;
        document.head.appendChild(script);
    }
}
</script>
