<template>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">

                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Repackage:{{ SupplierName }}
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-light" @click="backtoSupplier">
                            Back To Repackage Product List
                        </a>
                        <a class="btn btn-sm btn-success" @click="openModal">
                            Add Repackage Product
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />

                <div class="card">
                    <div class="card-header   card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-5">
                            <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input
                                        value="" v-model="searchTerm" type="text" placeholder="Search table"
                                        @input="fetchData"></label>
                            </div>
                        </div>
                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                        <template #table-row="props">
                            <span v-if="props.column.field === 'actions'">
                                
                                <!--<a @click="handleEdit(props.row)" class="btn btn-sm btn-icon btn-clear btn-light"
                                    href="#">
                                    <i class="ki-filled ki-notepad-edit">
                                    </i>
                                </a>-->

                                <a @click="handleDelete(props.row)" class="btn btn-sm btn-icon btn-clear btn-light"
                                    href="#">
                                    <i class="ki-filled ki-trash">
                                    </i>
                                </a>
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                    </vue-good-table>

                    <Modal :isVisible="showModal" @update:isVisible="showModal = $event">
                        <template v-slot:header>
                            <h2>Add Repackage Product</h2>
                        </template>
                        <template v-slot:body>
                            <form @submit.prevent="submitForm">

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Product Code
                                        </label>


                                        <div style="position: relative"
                                            class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            <!-- Use relative positioning for the container -->
                                            <input required type="text" v-model="searchQuery" ref="product_code"
                                                class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                @keyup="handleKeyup" placeholder="Type to search..." />
                                            <div class="suggestion-list-container">
                                                <ul v-if="suggestions.length" class="suggestion-list">
                                                    <li v-for="(suggestion, index) in suggestions" :key="index"
                                                        @click="selectSuggestion(suggestion)">
                                                        {{ suggestion.product_code }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Product Name
                                        </label>
                                        <input ref="product_name"
                                            class="appearance-none block w-full text-white border bg-gray-600 border-gray-500 rounded py-3 px-4 mb-3 leading-tight "
                                            type="text" readonly>
                                    </div>
                                </div>

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Group Number
                                        </label>
                                        <input v-model="form.group_no" min="0" max="9999" value="0"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="" type="number" required>
                                    </div>
                                </div>

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Quantity
                                        </label>
                                        <input v-model="form.quantity" min="1" max="999999999999999"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="grid-deparment-name" type="number" required>
                                    </div>
                                </div>

                                <div class="flex justify-end mt-4">
                                    <button type="button" @click="closeModal"
                                        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-1">Close</button>
                                    <button type="submit"
                                        class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Save</button>
                                </div>
                            </form>
                        </template>
                    </Modal>

                     <!---   
                    <Modal :isVisible="showModal2" @update:isVisible="showModal2 = $event" @click.stop>
                        <template v-slot:header>
                            <h2>Edit Province</h2>
                        </template>
                        <template v-slot:body>
                            <form @submit.prevent="updateForm">

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Product Code
                                        </label>
                                        <div style="position: relative"
                                            class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            
                                            <input type="text" v-model="searchQuery"
                                                class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                @keyup="handleKeyup" placeholder="Type to search..." />
                                            <div class="suggestion-list-container">
                                                <ul v-if="suggestions.length" class="suggestion-list">
                                                    <li v-for="(suggestion, index) in suggestions" :key="index"
                                                        @click="selectSuggestion(suggestion)">
                                                        {{ suggestion.product_code }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Product Name
                                        </label>
                                        <input v-model="currentItem.product_name"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="grid-deparment-name" type="text" required>
                                    </div>
                                </div>

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Product Description
                                        </label>
                                        <textarea v-model="currentItem.supplier_product_description"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="grid-deparment-name" type="text" required></textarea>
                                    </div>
                                </div>

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Product cost
                                        </label>
                                        <input v-model="currentItem.supplier_product_cost_price"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="grid-deparment-name" type="text" required>
                                    </div>
                                </div>

                                <div class="flex justify-end mt-4">
                                    <button type="button" @click="closeModal2"
                                        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-1">Close</button>
                                    <button type="submit"
                                        class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Update</button>
                                </div>
                            </form>
                        </template>
                    </Modal>-->

                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>

import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import { useRoute } from 'vue-router';
import Notification from '@/components/utils/Notification.vue';

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default {
    name: 'SupplieProductsPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
    },
    created() {
        // Initialize the debounced function
        this.fetchSuggestionsDebounced = debounce(this.fetchSuggestions, 1000);
    },
    mounted() {
        // alert(route.params.addNow); 
    },
    setup() {
        const route = useRoute();
        const SupplierName = ref(''); // Define the parent category name as a ref
        const Repackage_id = ref('');
        const columns = ref([
            { label: '#', field: 'repackage_id', type: 'number', sortable: true },
            { label: 'Product Code', field: 'product_code', sortable: true },
            { label: 'Product Name', field: 'product_name', sortable: true },
            { label: 'Group No.', field: 'group', sortable: true },
            { label: 'Quantity', field: 'quantity', sortable: true },
            {
                sortable: false,
                label: 'Actions',
                field: 'actions',
                type: 'actions',
                actions: [

                    {
                        label: 'Edit',
                        icon: 'edit',
                        onClick: ""
                    },
                    {
                        label: 'Delete',
                        icon: 'delete',
                        onClick: ""
                    }
                ]
            }
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(10);
        const sortField = ref('product_name');
        const sortType = ref('asc');

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value
        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            const parentCat = route.params.id;

            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                };
                const { data } = await apiClient.get(`/repackage_product_process/${parentCat}`, { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;
                SupplierName.value = data.parent.product_name;
                Repackage_id.value = data.parent.product_id;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            route,
            isLoading: false,
            SupplierName,
            Repackage_id

        };
    },
    methods: {

        handleKeyup() {
            this.fetchSuggestionsDebounced(); // Call the debounced function
        },
        async fetchSuggestions() {
            if (this.searchQuery.length > 2) {
                this.$refs.product_name.value = "Searching....";
                try {
                    const response = await apiClient.get("/product/code/" + this.searchQuery);

                    if (response.data.status == true) { 
                        this.suggestions = response.data.data;
                        
                        if(response.data.data.length==0){
                            this.$refs.product_name.value = " Product not Found! ";
                        }else{
                            this.$refs.product_name.value = "";

                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters
            }
        },
        selectSuggestion(suggestion) {
            this.searchQuery = suggestion.product_code; // Populate the input with the selected suggestion
            this.productSearch = suggestion;            
            this.$refs.product_name.value = suggestion.product_name;
            this.form.product_id = suggestion.product_id;          
            this.suggestions = []; // Clear suggestions list
        },
      
        backtoSupplier() {
            this.$router.push({ name: 'Repackage_list' }); // Redirect on success
        },
        handleEdit(data) {
            this.showInfo(data.repackage_id);
        },
        handleDelete(data) {

            let id = data.repackage_id;

            Swal.fire({
                title: 'Are you sure do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteData(id);
                }
            });

        },
        openModal() {
            this.showModal = true;

            this.$nextTick(() => {
            this.productSearch ="";
            this.$refs.product_code.value="";
            
            });
            
            
        },
        closeModal() {
            this.showModal = false;
            
        },
        closeModal2() {
            this.showModal2 = false;
        },
        /*async showInfo(id) {
            const response = await apiClient.get(`/repackage_product_process/${id}/info`);       
            this.currentItem = response.data.data; // Store the fetched data
            this.showModal2 = true; // Show the modal
        },*/
        async deleteData(id) {
            let loader = this.$loading.show();
            let repackage_id = this.$route.params.id;
            try {
                const response = await apiClient.delete(`/repackage_product_process/${repackage_id}/delete_item`, { data: { id: id } });
                if (response.data.status == true) { // or check for other success c
                    this.$refs.notificationRef.show('Successfully Deleted', 'success', '1');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                loader.hide();
            }
        },
        /*async updateForm() {
            const parentCat = this.$route.params.id; // Accessing route params via 'this'          

            try {

                const response = await apiClient.put(`/repackage_product_process/${parentCat}/update`, this.currentItem);
                if (response.data.status == true) { // or check for other success 
                    this.showModal2 = false;
                    this.$refs.notificationRef.show('Successfully Updated', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error updatting form:', error.response);

            }
        },*/
        async submitForm() {

            if(this.productSearch==""){
                this.$refs.notificationRef.show('Product not Found!', 'error', '0');
            }
            const parentCat = this.$route.params.id; // Accessing route params via 'this'
            let repackage_id = this.$route.params.id;
            this.form.repackage_id = repackage_id;

            try {
                const response = await apiClient.post(`/repackage_product_process/${repackage_id}/save`, this.form);

                if (response.data.status == true) {
                    this.showModal = false;
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        }

    },
    data() {
        return {
            showModal: false,
            form: {
                group_no: 0,
                repackage_id: 0,
                quantity:1
            },
            showModal2: false,
            currentItem: '',
            suggestions: [],
            searchQuery: "",
            productSearch: []
        };
    },


}
</script>


<style>
.suggestion-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 1050;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    /* Match input width or adjust as needed */
    max-width: 524px;
    /* Optional: limit maximum width */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional: add shadow for better visibility */
}

.suggestion-list-container {
    position: absolute;
    /* Position relative to the input */
    top: 100%;
    /* Adjust to place list directly below input */
    left: 0;
    right: 0;
    /* Ensure it aligns with the input width */
}

.suggestion-list li {
    padding: 10px;
    cursor: pointer;
}

.suggestion-list li:hover {
    background-color: #f0f0f0;
}
</style>