<template>
    <MainLayout>
      <template v-slot:default>
         <!-- begin: container -->
         <div class="container-fixed" id="content_container"></div>
                    <!-- end: container -->
                    <!-- begin: container -->
                    <div class="container-fixed">
                        <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                            <div class="flex flex-col justify-center gap-2">
                                <h1 class="text-xl font-semibold leading-none text-gray-900"> Dashboard </h1>
                               
                            </div>
                            
                        </div>
                    </div>
                    <!-- end: container -->
                    <!-- begin: container -->
                    <div class="container-fixed">
                        <div class="grid gap-5 lg:gap-7.5">
                            <!-- begin: grid -->
                            <div class="grid lg:grid-cols-3 gap-y-5 lg:gap-7.5 items-stretch">
                                
                                   
                               
                            </div>
                            <!-- end: grid -->
                            <!-- end: grid -->
                        </div>
                    </div>
                    <!-- end: container -->
      </template>
    </MainLayout>
  </template>
  
  <script>
  import MainLayout from './MainLayout.vue'
  export default {
    name: 'DashboardPage',
    components: {
      MainLayout
    }
  }
  </script>